<template>
  <div id="contact" class="md:flex">
    <div id="hand-shake" class="px-4 py-6 bg-cover background-image relative md:w-1/2 md:bg-center" :style="bgImage">
      <div class="relative z-10">
        <p class="text-black text-left text-2xl font-bold md:ml-8">Let’s talk about your <br
            class="hidden md:inline-block">cash offer!</p>
        <p class="text-left text-2xl font-bold mt-4 md:ml-8"
           :class="color === 'blue' ? 'text-legacy-call-blue' : 'text-legacy-orange'">Call<br>630-454-9184</p>
      </div>
    </div>
    <div id="form" class="p-4 md:w-1/2 md:shadow md:shadow-2xl z-50 md:pb-40">
      <p class="text-xl font-bold md:mt-4">I want to work with <br class="hidden">Legacy Homes Group</p>
      <p v-if="sent" class="text-green-600 mt-2 font-bold">Your Message was Successfully Sent!<br>We will get back to you shortly.</p>
      <p v-if="emailError" class="text-red-600 mt-2 font-bold">Sorry your message couldn't be sent. Please try again.</p>
      <div v-if="loading" class="loader-container">
        <div class="loader"></div>
      </div>
      <form v-if="!sent && !loading" @submit.prevent="sendEmail" action="" class="text-black">
        <input required type="text" v-model="name" placeholder="Name"
               class="bg-legacy-gray shadow shadow-inner p-2 w-11/12 my-4 inner-shadow">
        <input required type="email" v-model="email" placeholder="Email"
               class="bg-legacy-gray p-2 w-11/12 my-4 inner-shadow">
        <input required type="text" v-model="phone" placeholder="Phone Number"
               class="bg-legacy-gray p-2 w-11/12 my-4 inner-shadow">
        <textarea required type="text" v-model="message" rows="4" placeholder="Message"
                  class="bg-legacy-gray p-2 w-11/12 my-4 inner-shadow"></textarea>
        <input type="submit" value="Submit" class="text-white font-bold px-8 py-2 cursor-pointer"
               :class="color === 'blue' ? 'bg-legacy-blue' : 'bg-legacy-orange'">
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "TheContact",
  props: {
    color: {
      type: String,
      default: "blue"
    }
  },
  data() {
    return {
      bgImage: `background-image: url(${require('@/assets/hand-shake.jpeg')})`,
      name: '',
      email: '',
      phone: '',
      message: '',
      sent: false,
      loading: false,
      emailError: false,
    }
  },
  methods: {
    async sendEmail() {
      this.emailError = false;
      this.loading = true;
      const data = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.message
      };
      if (!data.name || !data.email || !data.phone || !data.message) return alert('Please fill out all fields');

      try {
        const response = await axios.post('https://gluxhidt78.execute-api.us-east-1.amazonaws.com/prod', data);
        console.log(response);
        this.sent = true;
      } catch (error) {
        this.emailError = true;
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
input::placeholder, textarea::placeholder {
  color: black;
}

#hand-shake::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.1); /* this adds a white overlay with 50% opacity */
}

.inner-shadow {
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.25);
}

#form {
  box-shadow: -4px 0 15px -5px rgba(0, 0, 0, 1);
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid blue;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>