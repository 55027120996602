import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import AboutPage from './components/AboutPage.vue';
import OurProcessPage from './components/OurProcessPage.vue';
import ContactPage from './components/ContactPage.vue';
import App from './App.vue';
import './output.css'

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/about',
        name: 'AboutPage',
        component: AboutPage,
    },
    {
        path: '/our-process',
        name: 'OurProcessPage',
        component: OurProcessPage,
    },
    {
        path: '/contact',
        name: 'ContactPage',
        component: ContactPage,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

createApp(App).use(router).mount('#app');
