<template>
  <the-header></the-header>
  <div id="body" class="bg-legacy-orange text-white py-12 px-4 font-bold text-left italic md:p-28">
    <p>We’re prepared to help you with any situation: behind on taxes, health issues, termite damage, unfinished construction, storm or fire damage, inherited home, bankruptcy,
      divorce, job transfer, behind on the mortgage, over leveraged, had it listed but it didn’t sell, downsizing, upgrading or something else we can help with. Our process is simple, quick and convenient. After an initial phone call to introduce ourselves and collect some details we’ll schedule a day and time to visit the property. Our intention is to
      buy your house, not just make you a sight unseen unrealistic offer, so seeing the interior and exterior, of the property, is essential to us being able to present our best offer price.
      <br><br>
      Once at the property we will look at the plumbing, electric, furnace, windows, roof, foundation, basement or crawl space and the kitchen, baths and general cosmetic condition. We’ll take lots of notes and, if you’re comfortable, pictures. Many times we can provide you an offer during our visit but if we need to head back to the office for
      more analysis we’ll have you our offer in 48 hours.
      <br><br>
      Our offers always include an “as is” purchase....we will not ask you to do any fixes... we will pay all title closing fees and charges and of course you don’t pay any realtor commissions. Once we agree on the price and close date we both sign a simple 2 page “Offer to Purchase Real Estate Agreement” and we move forward with getting you your money. All sales are transacted via an attorney and title company. If you
      don’t have an attorney, we can help there too.
      <br><br>
      Ready to get started: simply fill out and submit the no obligation “I want to work with Legacy Homes Group” form and we’ll contact you soon!</p>
  </div>
  <div id="cta" class="px-4 py-8 bg-cover background-image relative md:min-h-[400px] md:flex md:items-center md:justify-center md:bg-center" :style="bgImage">
    <div class="relative z-10 md:flex md:items-center md:justify-evenly md:w-full">
      <p class="text-black text-left text-xl font-bold text-white">Do you have a house to sell?<br>Get your no obligation cash offer now!</p>
      <router-link class="bg-legacy-blue text-white px-8 py-2 rounded mt-8 inline-block font-bold md:mt-0 md:px-16" :to="linkUrl">{{ linkText }}</router-link>
    </div>
  </div>
  <the-footer></the-footer>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";

export default {
  name: "OurProcessPage",
  components: {TheFooter, TheHeader},
  data() {
    return {
      linkText: 'CALL US',
      linkUrl: '/contact',
      bgImage: `background-image: url(${require('@/assets/kitchen.jpg')})`

    };
  },
  mounted() {
    // Detect the device type
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // Update the link URL based on the device type
    if (isMobile) {
      this.linkUrl = 'tel:123-456-7890';
    }
  }
};
</script>

<style scoped>
#cta::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2); /* this adds a white overlay with 50% opacity */
}
</style>