<template>
  <div class="">
    <the-header type="contact"></the-header>
    <div class="w-full h-4 bg-orange-500"></div>
    <the-contact color="blue"></the-contact>
    <the-footer></the-footer>
  </div>
</template>


<script>
import TheContact from "@/components/TheContact.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: "ContactPage",
  components: {TheHeader, TheFooter, TheContact}
}
</script>

<style scoped>

</style>