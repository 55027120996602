<template>
  <div class="">
    <the-header :show-hero-message="true"></the-header>
    <div id="body" class="bg-legacy-orange text-white py-12 px-4 font-bold text-left italic md:p-28">
      <p>Legacy Homes Group is a woman owned company buying properties all over Chicagoland, no matter the condition, the
        reason or the timeframe. Nothing is more important to us than helping a seller out of their situation and
        transforming a home for the next buyer to create years of memories. It’s not unusual for an entire block to be
        revitalized if a home we have purchased has been vacant and neglected. If you have a house to sell, we can help
        and every conversation is strictly confidential.<br><br>

        We’ve been buying homes for more than ten years and have the offer process down to a science therefore, we just
        don’t make offers, we buy homes with a simple, stress free, transparent and quick process. Since we buy with cash,
        you don’t need to sign a mountain of papers and you get to put more money in your pocket by avoiding all the
        annoying fees such as realtor commissions and title charges. Speaking of your money, we can close in an average of
        three weeks so you get your money quickly!</p>
    </div>
    <the-contact color="orange"></the-contact>
    <the-footer></the-footer>
  </div>
</template>


<script>
import TheContact from "@/components/TheContact.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: "HomePage",
  components: {TheHeader, TheFooter, TheContact}
}
</script>

<style scoped>

</style>