<template>
  <div id="header">
    <div id="hero" class="px-4 py-6 bg-cover background-image relative md:min-h-[330px]" :class="this.type === 'home' ? 'bg-bottom' : 'bg-center' " :style="bgImage">
      <div class="relative z-10 flex items-center justify-center py-14 md:pt-0">
        <img class="mx-auto md:hidden" src="@/assets/Legacy-Homes-Group-Logo.svg" alt="Legacy Homes Group Logo">
        <div class="hidden w-full md:inline-block">
          <div class="hidden md:flex text-white items-center justify-evenly font-bold">
            <div class="w-1/4 min-w-[240px] flex items-center justify-between">
              <router-link to="/" active-class="active-link">ABOUT US</router-link>
              <router-link to="/our-process" active-class="active-link">OUR PROCESS</router-link>
            </div>
            <img class="hidden md:inline-block pl-4" src="@/assets/Legacy-Homes-Group-Logo.svg"
                 alt="Legacy Homes Group Logo">
            <router-link class="w-1/4" to="/contact" active-class="active-link">CONTACT US</router-link>
          </div>
          <p v-if="this.showHeroMessage"
             class="hidden md:inline-block text-center text-white font-bold text-2xl px-[10%] capitalize mt-16">Legacy
            Homes Group is a woman owned company buying properties
            all over Chicagoland, No matter the condition, the reason Or the timeframe.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheHeader",
  props: {
    type: {
      type: String,
      default: "home"
    },
    showHeroMessage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    bgImage() {
      let imagePath;
      if (this.type === "contact") {
        imagePath = `background-image: url(${require('@/assets/living-room.jpg')})`
      } else {
        imagePath = `background-image: url(${require('@/assets/houses.jpeg')})`
      }
      return imagePath;
    },
  }
}
</script>

<style scoped>
#hero::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2); /* this adds a white overlay with 50% opacity */
}

</style>