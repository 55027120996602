<template>
  <div class="bg-legacy-blue text-white py-12 px-2">
    <div class="md:flex md:items-center md:justify-center md:mx-auto md:mb-10">
      <p class="font-bold text-white">Legacy Homes Group</p>
      <p class="hidden md:inline-block">&nbsp;|&nbsp;</p>
      <a href="mailto:contact@legacyhomesgroup.net">Email contact@legacyhomesgroup.net</a>
      <p class="hidden md:inline-block">&nbsp;|&nbsp;</p>
      <p>Phone (630)-454-9184</p>
    </div>
    <div class="flex items-center justify-evenly py-2 md:mb-10">
      <router-link to="/" @click="scrollToTop">About Us</router-link>
      <router-link to="/our-process" @click="scrollToTop">Our Process</router-link>
      <router-link to="/contact" @click="scrollToTop">Contact Us</router-link>
    </div>
  <p>&copy; {{this.year}} Legacy Homes Group. All Rights Reserved.</p>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style scoped>

</style>