<template>
  <div class="font-sans" @click="closeMenu">
    <img @click.stop="toggleMenu" class="absolute top-0 left-0 z-20 ml-4 mt-4 cursor-pointer md:hidden" src="@/assets/menu.svg"
         alt="Hamburger Menu">
    <div class="z-50 fixed w-4/5 bg-legacy-call-blue text-white text-2xl text-left font-bold flex flex-col items-start"
         :class="['sidebar', { 'show-menu': show }]" id="menu" ref="menu">
      <img class="self-center" src="@/assets/Legacy-Homes-Group-Logo.svg" alt="Legacy Homes Group Logo">
      <div class="mt-8 mb-2" @click="show = false">
        <router-link to="/" active-class="active-link">About Us</router-link>
      </div>
      <div class="my-2" @click="show = false">
        <router-link to="/our-process" active-class="active-link">Our Process</router-link>
      </div>
      <div class="my-2" @click="show = false">
        <router-link to="/contact" active-class="active-link">Contact Us</router-link>
      </div>
    </div>
    <div class="overlay" :class="['overlay-show', { 'show-menu': show }]" @click="show = false"></div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggleMenu(event) {
      this.show = !this.show;
      event.stopPropagation();
    },
    closeMenu(event) {
      const menu = this.$refs.menu;
      if (!menu.contains(event.target)) {
        this.show = false;
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.sidebar {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  padding: 20px;
  background-color: #f8f9fa;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.show-menu {
  transform: translateX(0);
}

.overlay {
  position: fixed;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
}

.overlay-show.show-menu {
  z-index: 49;
  opacity: 1;
  visibility: visible;
}

.active-link {
  color: #F58800;
}
</style>
