<template>
  <h1 style="color: #000;">I am the about page</h1>
</template>

<script>
export default {
  name: "AboutPage"
}
</script>

<style scoped>

</style>